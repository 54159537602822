import React, { useCallback, useContext, useEffect, useState } from "react";
import { distinctUntilChanged, map } from "rxjs";
import type {
  AccountState,
  WalletSelector,
  WalletSelectorState,
  // @ts-ignore
} from "@near-wallet-selector/core";
// @ts-ignore
import { setupWalletSelector } from "@near-wallet-selector/core";
// @ts-ignore
import type { WalletSelectorModal } from "@near-wallet-selector/modal-ui";
// @ts-ignore
import { setupModal } from "@near-wallet-selector/modal-ui";
// @ts-ignore
import { setupNearWallet } from "@near-wallet-selector/near-wallet";
// @ts-ignore
import { setupMyNearWallet } from "@near-wallet-selector/my-near-wallet";
// @ts-ignore
import { setupSender } from "@near-wallet-selector/sender";
// @ts-ignore
import { setupMathWallet } from "@near-wallet-selector/math-wallet";
import { GUESTBOOK_CONTRACT_ID } from "../constants";
// import { setupMeteorWallet } from "@meteorwallet/near-wallet-selector";
import { setupMeteorWallet } from "../../../meteor-wallet-selector/src/index";
// import { setupMeteorWallet } from "@near-wallet-selector/meteor-wallet";

declare global {
  interface Window {
    selector: WalletSelector;
    modal: WalletSelectorModal;
  }
}

interface WalletSelectorContextValue {
  selector: WalletSelector;
  modal: WalletSelectorModal;
  accounts: Array<AccountState>;
  accountId: string | null;
  setAccountId: (accountId: string) => void;
}

const WalletSelectorContext =
  React.createContext<WalletSelectorContextValue | null>(null);

export const WalletSelectorContextProvider: React.FC<{ children: any }> = ({
  children,
}) => {
  const [selector, setSelector] = useState<WalletSelector | null>(null);
  const [modal, setModal] = useState<WalletSelectorModal | null>(null);
  const [accountId, setAccountId] = useState<string | null>(null);
  const [accounts, setAccounts] = useState<Array<AccountState>>([]);

  const syncAccountState = (
    currentAccountId: string | null,
    newAccounts: Array<AccountState>,
  ) => {
    if (!newAccounts.length) {
      localStorage.removeItem("accountId");
      setAccountId(null);
      setAccounts([]);

      return;
    }

    const validAccountId =
      currentAccountId &&
      newAccounts.some((x) => x.accountId === currentAccountId);
    const newAccountId = validAccountId
      ? currentAccountId
      : newAccounts[0].accountId;

    localStorage.setItem("accountId", newAccountId);
    setAccountId(newAccountId);
    setAccounts(newAccounts);
  };

  const init = useCallback(async () => {
    const _selector = await setupWalletSelector({
      network: "testnet",
      debug: true,
      modules: [
        setupMeteorWallet(),
        setupNearWallet(),
        setupMyNearWallet(),
        setupSender(),
        setupMathWallet(),
      ],
    });

    const _modal = setupModal(_selector, { contractId: GUESTBOOK_CONTRACT_ID });
    const state = _selector.store.getState();

    syncAccountState(localStorage.getItem("accountId"), state.accounts);

    window.selector = _selector;
    window.modal = _modal;

    setSelector(_selector);
    setModal(_modal);
  }, []);

  useEffect(() => {
    init().catch((err) => {
      console.error(err);
      alert("Failed to initialise wallet selector");
    });
  }, [init]);

  useEffect(() => {
    if (!selector) {
      return;
    }

    const subscription = selector.store.observable
      .pipe(
        map((state: WalletSelectorState) => state.accounts),
        distinctUntilChanged(),
      )
      .subscribe((nextAccounts) => {
        console.log("Accounts Update", nextAccounts);

        syncAccountState(accountId, nextAccounts);
      });

    return () => subscription.unsubscribe();
  }, [selector, accountId]);

  if (!selector || !modal) {
    return null;
  }

  return (
    <WalletSelectorContext.Provider
      value={{
        selector,
        modal,
        accounts,
        accountId,
        setAccountId,
      }}
    >
      {children}
    </WalletSelectorContext.Provider>
  );
};

export function useWalletSelector() {
  const context = useContext(WalletSelectorContext);

  if (!context) {
    throw new Error(
      "useWalletSelector must be used within a WalletSelectorContextProvider",
    );
  }

  return context;
}
