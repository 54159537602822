import React, { Fragment, useCallback, useEffect, useState } from "react";
import { providers, utils } from "near-api-js";
import type {
  AccountView,
  CodeResult,
} from "near-api-js/lib/providers/provider";
// @ts-ignore
import { setupExportSelectorModal } from "@near-wallet-selector/account-export";

import type { Account, Message } from "../interfaces";
import { useWalletSelector } from "../contexts/WalletSelectorContext";
import { GUESTBOOK_CONTRACT_ID } from "../constants";

import SignIn from "./SignIn";
import Form from "./Form";
import Messages from "./Messages";
import { IMPORT_HASH } from "../GuestbookMeteorConstants";
import { NEAR_BASE_CONNECT_CONFIG_LOCALNET } from "../../../meteor-common/src/modules_external/near/near_static_data";

const SUGGESTED_DONATION = "0";
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const BOATLOAD_OF_GAS = utils.format.parseNearAmount("0.00000000003")!;

const Content: React.FC = () => {
  const { selector, modal, accounts, accountId, setAccountId } =
    useWalletSelector();
  const [account, setAccount] = useState<Account | null>(null);
  const [messages, setMessages] = useState<Array<Message>>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const getAccount = useCallback(async (): Promise<Account | null> => {
    if (!accountId) {
      return null;
    }

    const { network } = selector.options;
    const provider = new providers.JsonRpcProvider({ url: network.nodeUrl });

    return provider
      .query<AccountView>({
        request_type: "view_account",
        finality: "final",
        account_id: accountId,
      })
      .then((data) => ({
        ...data,
        account_id: accountId,
      }));
  }, [accountId, selector.options]);

  const getMessages = useCallback(() => {
    const { network } = selector.options;
    const provider = new providers.JsonRpcProvider({ url: network.nodeUrl });

    return provider
      .query<CodeResult>({
        request_type: "call_function",
        account_id: GUESTBOOK_CONTRACT_ID,
        method_name: "getMessages",
        args_base64: "",
        finality: "optimistic",
      })
      .then((res) => JSON.parse(Buffer.from(res.result).toString()));
  }, [selector]);

  useEffect(() => {
    // TODO: don't just fetch once; subscribe!
    getMessages().then(setMessages);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!accountId) {
      return setAccount(null);
    }

    setLoading(true);

    getAccount().then((nextAccount) => {
      setAccount(nextAccount);
      setLoading(false);
    });
  }, [accountId, getAccount]);

  const handleSignIn = () => {
    modal.show();
  };

  const handleSignOut = async () => {
    const wallet = await selector.wallet();

    wallet.signOut().catch((err) => {
      console.log("Failed to sign out");
      console.error(err);
    });
  };

  const handleSwitchProvider = () => {
    modal.show();
  };

  const handleSwitchAccount = () => {
    const currentIndex = accounts.findIndex((x) => x.accountId === accountId);
    const nextIndex = currentIndex < accounts.length - 1 ? currentIndex + 1 : 0;

    const nextAccountId = accounts[nextIndex].accountId;

    setAccountId(nextAccountId);
    alert("Switched account to " + nextAccountId);
  };

  const handleVerifyOwner = async () => {
    try {
      const wallet = await selector.wallet();
      const owner = await wallet.verifyOwner({
        message: "test message for verification",
      });

      if (owner) {
        const verifySignature = owner.publicKey;

        alert(`Signature for verification: ${JSON.stringify(owner)}`);

        // owner.
      }
    } catch (err) {
      const message =
        err instanceof Error ? err.message : "Something went wrong";
      alert(message);
    }
  };

  const handleSignMessage = async () => {
    try {
      const wallet = await selector.wallet();
      const alloc = Buffer.alloc(32);
      const nonceArray = crypto.getRandomValues(alloc);
      const signed = await wallet.signMessage({
        message: "test message for sign",
        nonce: nonceArray,
        recipient: "test",
      });
      if (signed) {
        console.log(`Signature: ${JSON.stringify(signed)}`);
      }
    } catch (err) {
      const message =
        err instanceof Error ? err.message : "Something went wrong";
      alert(message);
    }
  };

  const handleSendWithYoctoNearTransactions = async () => {
    const { contract } = selector.store.getState();
    const wallet = await selector.wallet();

    await wallet.signAndSendTransactions({
      transactions: [
        {
          // Deploy your own version of https://github.com/near-examples/rust-counter using Gitpod to get a valid receiverId.
          signerId: accountId!,
          receiverId: "ft1.enleapstack.testnet",
          actions: [
            {
              type: "FunctionCall",
              params: {
                methodName: "something",
                args: {
                  receiver_id: "compound",
                  amount: "1000000000000000000000",
                  msg: "invest",
                },
                gas: BOATLOAD_OF_GAS,
                deposit: "1",
              },
            },
          ],
        },
      ],
    });
  };

  const handleSendMultipleTransactions = async () => {
    const { contract } = selector.store.getState();
    const wallet = await selector.wallet();

    await wallet.signAndSendTransactions({
      transactions: [
        {
          // Deploy your own version of https://github.com/near-examples/rust-counter using Gitpod to get a valid receiverId.
          signerId: accountId!,
          receiverId: "dev-1648806797290-14624341764914",
          actions: [
            {
              type: "FunctionCall",
              params: {
                methodName: "increment",
                args: {},
                gas: BOATLOAD_OF_GAS,
                deposit: utils.format.parseNearAmount("0")!,
              },
            },
          ],
        },
        {
          signerId: accountId!,
          receiverId: GUESTBOOK_CONTRACT_ID,
          actions: [
            {
              type: "FunctionCall",
              params: {
                methodName: "addMessage",
                args: { text: "Hello World!" },
                gas: BOATLOAD_OF_GAS,
                deposit: utils.format.parseNearAmount("0")!,
              },
            },
          ],
        },
      ],
    });
  };

  const handleSendMultipleTransactionsWithoutSignerId = async () => {
    const { contract } = selector.store.getState();
    const wallet = await selector.wallet();

    await wallet.signAndSendTransactions({
      transactions: [
        {
          // Deploy your own version of https://github.com/near-examples/rust-counter using Gitpod to get a valid receiverId.
          // signerId: accountId!,
          receiverId: "dev-1648806797290-14624341764914",
          actions: [
            {
              type: "FunctionCall",
              params: {
                methodName: "increment",
                args: {},
                gas: BOATLOAD_OF_GAS,
                deposit: utils.format.parseNearAmount("0")!,
              },
            },
          ],
        },
        {
          // signerId: accountId!,
          receiverId: contract!.contractId,
          actions: [
            {
              type: "FunctionCall",
              params: {
                methodName: "addMessage",
                args: { text: "Hello World!" },
                gas: BOATLOAD_OF_GAS,
                deposit: utils.format.parseNearAmount("0")!,
              },
            },
          ],
        },
      ],
    });
  };

  const handleSendMultiNearTransactions = async () => {
    const { contract } = selector.store.getState();
    const wallet = await selector.wallet();

    await wallet.signAndSendTransactions({
      transactions: [
        {
          // Deploy your own version of https://github.com/near-examples/rust-counter using Gitpod to get a valid receiverId.
          signerId: accountId!,
          receiverId: "ft1.enleapstack.testnet",
          actions: [
            {
              type: "FunctionCall",
              params: {
                methodName: "something",
                args: {
                  receiver_id: "compound",
                  amount: "1000000000000000000000",
                  msg: "invest",
                },
                gas: BOATLOAD_OF_GAS,
                deposit: utils.format.parseNearAmount("0.1")!,
              },
            },
            {
              type: "FunctionCall",
              params: {
                methodName: "someOtherThing",
                args: {
                  receiver_id: "compound",
                  amount: "1000000000000000000000",
                  msg: "invest",
                },
                gas: BOATLOAD_OF_GAS,
                deposit: utils.format.parseNearAmount("1.1")!,
              },
            },
          ],
        },
      ],
    });
  };

  const handleSendFtTransactions = async () => {
    const { contract } = selector.store.getState();
    const wallet = await selector.wallet();

    await wallet.signAndSendTransactions({
      transactions: [
        {
          // Deploy your own version of https://github.com/near-examples/rust-counter using Gitpod to get a valid receiverId.
          signerId: accountId!,
          receiverId: "ft1.enleapstack.testnet",
          actions: [
            {
              type: "FunctionCall",
              params: {
                methodName: "ft_transfer",
                args: {
                  receiver_id: "compound",
                  amount: "1000000000000000000000",
                  msg: "invest",
                },
                gas: BOATLOAD_OF_GAS,
                deposit: utils.format.parseNearAmount("0")!,
              },
            },
          ],
        },
      ],
    });
  };

  const handleSendFtWithNearTransactions = async () => {
    const { contract } = selector.store.getState();
    const wallet = await selector.wallet();

    await wallet.signAndSendTransactions({
      transactions: [
        {
          // Deploy your own version of https://github.com/near-examples/rust-counter using Gitpod to get a valid receiverId.
          signerId: accountId!,
          receiverId: "usdn.testnet",
          actions: [
            {
              type: "FunctionCall",
              params: {
                methodName: "ft_transfer",
                args: {
                  receiver_id: "compound",
                  amount: "2000000000000000000000",
                  msg: "invest",
                },
                gas: BOATLOAD_OF_GAS,
                deposit: utils.format.parseNearAmount("10")!,
              },
            },
          ],
        },
      ],
    });
  };

  const handleSendMultiFtTransactions = async () => {
    const { contract } = selector.store.getState();
    const wallet = await selector.wallet();

    await wallet.signAndSendTransactions({
      transactions: [
        {
          // Deploy your own version of https://github.com/near-examples/rust-counter using Gitpod to get a valid receiverId.
          signerId: accountId!,
          receiverId: "ft1.enleapstack.testnet",
          actions: [
            {
              type: "FunctionCall",
              params: {
                methodName: "ft_transfer",
                args: {
                  receiver_id: "compound",
                  amount: "1000000000000000000000",
                  msg: "invest",
                },
                gas: BOATLOAD_OF_GAS,
                deposit: utils.format.parseNearAmount("0")!,
              },
            },
          ],
        },
        {
          // Deploy your own version of https://github.com/near-examples/rust-counter using Gitpod to get a valid receiverId.
          signerId: accountId!,
          receiverId: "usdn.testnet",
          actions: [
            {
              type: "FunctionCall",
              params: {
                methodName: "ft_transfer",
                args: {
                  receiver_id: "compound",
                  amount: "2000000000000000000000",
                  msg: "invest",
                },
                gas: BOATLOAD_OF_GAS,
                deposit: utils.format.parseNearAmount("10")!,
              },
            },
          ],
        },
      ],
    });
  };

  const handleSendManyManyFtTransactions = async () => {
    const { contract } = selector.store.getState();
    const wallet = await selector.wallet();

    await wallet.signAndSendTransactions({
      transactions: [
        {
          // Deploy your own version of https://github.com/near-examples/rust-counter using Gitpod to get a valid receiverId.
          signerId: accountId!,
          receiverId: "ft1.enleapstack.testnet",
          actions: [
            {
              type: "FunctionCall",
              params: {
                methodName: "ft_transfer",
                args: {
                  receiver_id: "compound",
                  amount: "1000000000000000000000",
                  msg: "invest",
                },
                gas: BOATLOAD_OF_GAS,
                deposit: utils.format.parseNearAmount("0")!,
              },
            },
          ],
        },
        {
          // Deploy your own version of https://github.com/near-examples/rust-counter using Gitpod to get a valid receiverId.
          signerId: accountId!,
          receiverId: "usdn.testnet",
          actions: [
            {
              type: "FunctionCall",
              params: {
                methodName: "ft_transfer",
                args: {
                  receiver_id: "compound",
                  amount: "2000000000000000000000",
                  msg: "invest",
                },
                gas: BOATLOAD_OF_GAS,
                deposit: utils.format.parseNearAmount("10")!,
              },
            },
            {
              type: "FunctionCall",
              params: {
                methodName: "ft_transfer",
                args: {
                  receiver_id: "compound",
                  amount: "2000000000000000000000",
                  msg: "invest",
                },
                gas: BOATLOAD_OF_GAS,
                deposit: utils.format.parseNearAmount("10")!,
              },
            },
          ],
        },
        {
          // Deploy your own version of https://github.com/near-examples/rust-counter using Gitpod to get a valid receiverId.
          signerId: accountId!,
          receiverId: "usdn.testnet",
          actions: [
            {
              type: "FunctionCall",
              params: {
                methodName: "ft_transfer",
                args: {
                  receiver_id: "compound",
                  amount: "2000000000000000000000",
                  msg: "invest",
                },
                gas: BOATLOAD_OF_GAS,
                deposit: utils.format.parseNearAmount("10")!,
              },
            },
          ],
        },
        {
          // Deploy your own version of https://github.com/near-examples/rust-counter using Gitpod to get a valid receiverId.
          signerId: accountId!,
          receiverId: "tinkerunion_nft.testenleap.testnet",
          actions: [
            {
              type: "FunctionCall",
              params: {
                methodName: "ft_transfer",
                args: {
                  receiver_id: "compound",
                  amount: "2000000000000000000000",
                  msg: "invest",
                },
                gas: BOATLOAD_OF_GAS,
                deposit: utils.format.parseNearAmount("10")!,
              },
            },
          ],
        },
        {
          // Deploy your own version of https://github.com/near-examples/rust-counter using Gitpod to get a valid receiverId.
          signerId: accountId!,
          receiverId: "x.testcandy.testnet",
          actions: [
            {
              type: "FunctionCall",
              params: {
                methodName: "ft_transfer",
                args: {
                  receiver_id: "compound",
                  amount: "2000000000000000000000",
                  msg: "invest",
                },
                gas: BOATLOAD_OF_GAS,
                deposit: utils.format.parseNearAmount("10")!,
              },
            },
          ],
        },
      ],
    });
  };

  const handleSendNftTransactions = async () => {
    const { contract } = selector.store.getState();
    const wallet = await selector.wallet();

    await wallet.signAndSendTransactions({
      transactions: [
        {
          // Deploy your own version of https://github.com/near-examples/rust-counter using Gitpod to get a valid receiverId.
          signerId: accountId!,
          receiverId: "nft1.enleapstack.testnet",
          actions: [
            {
              type: "FunctionCall",
              params: {
                methodName: "nft_transfer",
                args: {
                  receiver_id: "compound",
                  token_id: "1005",
                  msg: "invest",
                },
                gas: BOATLOAD_OF_GAS,
                deposit: utils.format.parseNearAmount("0")!,
              },
            },
          ],
        },
      ],
    });
  };

  const handleSendNftWithNearTransactions = async () => {
    const { contract } = selector.store.getState();
    const wallet = await selector.wallet();

    await wallet.signAndSendTransactions({
      transactions: [
        {
          // Deploy your own version of https://github.com/near-examples/rust-counter using Gitpod to get a valid receiverId.
          signerId: accountId!,
          receiverId: "nft1.enleapstack.testnet",
          actions: [
            {
              type: "FunctionCall",
              params: {
                methodName: "nft_transfer",
                args: {
                  receiver_id: "compound",
                  token_id: "1005",
                  msg: "invest",
                },
                gas: BOATLOAD_OF_GAS,
                deposit: utils.format.parseNearAmount("10")!,
              },
            },
          ],
        },
      ],
    });
  };

  const handleSendFtAndNftTransactions = async () => {
    const { contract } = selector.store.getState();
    const wallet = await selector.wallet();

    await wallet.signAndSendTransactions({
      transactions: [
        {
          // Deploy your own version of https://github.com/near-examples/rust-counter using Gitpod to get a valid receiverId.
          signerId: accountId!,
          receiverId: "ft1.enleapstack.testnet",
          actions: [
            {
              type: "FunctionCall",
              params: {
                methodName: "ft_transfer",
                args: {
                  receiver_id: "compound",
                  amount: "1000000000000000000000",
                  msg: "invest",
                },
                gas: BOATLOAD_OF_GAS,
                deposit: utils.format.parseNearAmount("10")!,
              },
            },
          ],
        },
        {
          // Deploy your own version of https://github.com/near-examples/rust-counter using Gitpod to get a valid receiverId.
          signerId: accountId!,
          receiverId: "usdn.testnet",
          actions: [
            {
              type: "FunctionCall",
              params: {
                methodName: "ft_transfer",
                args: {
                  receiver_id: "compound",
                  amount: "2000000000000000000000",
                  msg: "invest",
                },
                gas: BOATLOAD_OF_GAS,
                deposit: utils.format.parseNearAmount("10")!,
              },
            },
            {
              type: "FunctionCall",
              params: {
                methodName: "ft_transfer",
                args: {
                  receiver_id: "compound",
                  amount: "2000000000000000000000",
                  msg: "invest",
                },
                gas: BOATLOAD_OF_GAS,
                deposit: utils.format.parseNearAmount("10")!,
              },
            },
          ],
        },
        {
          // Deploy your own version of https://github.com/near-examples/rust-counter using Gitpod to get a valid receiverId.
          signerId: accountId!,
          receiverId: "nft1.enleapstack.testnet",
          actions: [
            {
              type: "FunctionCall",
              params: {
                methodName: "nft_transfer",
                args: {
                  receiver_id: "compound",
                  token_id: "1005",
                  msg: "invest",
                },
                gas: BOATLOAD_OF_GAS,
                deposit: utils.format.parseNearAmount("10")!,
              },
            },
          ],
        },
      ],
    });
  };

  const handleTransferSomeFundsToGuestbook = async () => {
    const wallet = await selector.wallet();
    await wallet.signAndSendTransaction({
      signerId: accountId!,
      receiverId: "pebbletest.testnet",
      actions: [
        {
          type: "Transfer",
          params: {
            deposit: utils.format.parseNearAmount("1.25"),
          },
        },
      ],
    });
  };

  const handleAddFullAccessKey = async () => {
    const wallet = await selector.wallet();

    await wallet.signAndSendTransactions({
      transactions: [
        {
          signerId: accountId!,
          receiverId: accountId!,
          actions: [
            {
              type: "AddKey",
              params: {
                publicKey:
                  "ed25519:6yjPa9QiwQC6RPwM1ho5BHXbyLkTJvADpgcWf9hjaPoj",
                accessKey: {
                  permission: "FullAccess",
                },
              },
            },
          ],
        },
      ],
    });
  };

  const handleRemoveFullAccessKey = async () => {
    const wallet = await selector.wallet();

    await wallet.signAndSendTransactions({
      transactions: [
        {
          signerId: accountId!,
          receiverId: accountId!,
          actions: [
            {
              type: "DeleteKey",
              params: {
                publicKey:
                  "ed25519:47DukAY98H5ow7Bic3iwFtaMDAnyEt3MV4U6wToVCkco",
              },
            },
          ],
        },
      ],
    });
  };

  const handleContractDeploy = async () => {
    const wallet = await selector.wallet();

    await wallet.signAndSendTransactions({
      transactions: [
        {
          signerId: accountId!,
          receiverId: accountId!,
          actions: [
            {
              type: "DeployContract",
              params: {
                code: Buffer.from("asdasdasdasd()"),
              },
            },
          ],
        },
      ],
    });
  };

  const handleDeleteAccount = async () => {
    const wallet = await selector.wallet();

    await wallet.signAndSendTransactions({
      transactions: [
        {
          signerId: accountId!,
          receiverId: accountId!,
          actions: [
            {
              type: "DeleteAccount",
              params: {
                beneficiaryId: "pebbledev.testnet",
              },
            },
          ],
        },
      ],
    });
  };

  const handleSendManyFtAndNftTransactions = async () => {
    const { contract } = selector.store.getState();
    const wallet = await selector.wallet();

    await wallet.signAndSendTransactions({
      transactions: [
        {
          // Deploy your own version of https://github.com/near-examples/rust-counter using Gitpod to get a valid receiverId.
          signerId: accountId!,
          receiverId: "ft1.enleapstack.testnet",
          actions: [
            {
              type: "FunctionCall",
              params: {
                methodName: "ft_transfer",
                args: {
                  receiver_id: "compound",
                  amount: "1000000000000000000000",
                  msg: "invest",
                },
                gas: BOATLOAD_OF_GAS,
                deposit: utils.format.parseNearAmount("0")!,
              },
            },
          ],
        },
        {
          // Deploy your own version of https://github.com/near-examples/rust-counter using Gitpod to get a valid receiverId.
          signerId: accountId!,
          receiverId: "usdn.testnet",
          actions: [
            {
              type: "FunctionCall",
              params: {
                methodName: "ft_transfer",
                args: {
                  receiver_id: "compound",
                  amount: "2000000000000000000000",
                  msg: "invest",
                },
                gas: BOATLOAD_OF_GAS,
                deposit: utils.format.parseNearAmount("10")!,
              },
            },
            {
              type: "FunctionCall",
              params: {
                methodName: "ft_transfer",
                args: {
                  receiver_id: "compound",
                  amount: "2000000000000000000000",
                  msg: "invest",
                },
                gas: BOATLOAD_OF_GAS,
                deposit: utils.format.parseNearAmount("10")!,
              },
            },
          ],
        },
        {
          // Deploy your own version of https://github.com/near-examples/rust-counter using Gitpod to get a valid receiverId.
          signerId: accountId!,
          receiverId: "usdn.testnet",
          actions: [
            {
              type: "FunctionCall",
              params: {
                methodName: "ft_transfer",
                args: {
                  receiver_id: "compound",
                  amount: "2000000000000000000000",
                  msg: "invest",
                },
                gas: BOATLOAD_OF_GAS,
                deposit: utils.format.parseNearAmount("10")!,
              },
            },
          ],
        },
        {
          // Deploy your own version of https://github.com/near-examples/rust-counter using Gitpod to get a valid receiverId.
          signerId: accountId!,
          receiverId: "tinkerunion_nft.testenleap.testnet",
          actions: [
            {
              type: "FunctionCall",
              params: {
                methodName: "ft_transfer",
                args: {
                  receiver_id: "compound",
                  amount: "2000000000000000000000",
                  msg: "invest",
                },
                gas: BOATLOAD_OF_GAS,
                deposit: utils.format.parseNearAmount("10")!,
              },
            },
          ],
        },
        {
          // Deploy your own version of https://github.com/near-examples/rust-counter using Gitpod to get a valid receiverId.
          signerId: accountId!,
          receiverId: "x.testcandy.testnet",
          actions: [
            {
              type: "FunctionCall",
              params: {
                methodName: "ft_transfer",
                args: {
                  receiver_id: "compound",
                  amount: "2000000000000000000000",
                  msg: "invest",
                },
                gas: BOATLOAD_OF_GAS,
                deposit: utils.format.parseNearAmount("10")!,
              },
            },
          ],
        },
        {
          // Deploy your own version of https://github.com/near-examples/rust-counter using Gitpod to get a valid receiverId.
          signerId: accountId!,
          receiverId: "nft1.enleapstack.testnet",
          actions: [
            {
              type: "FunctionCall",
              params: {
                methodName: "nft_transfer",
                args: {
                  receiver_id: "compound",
                  token_id: "1005",
                  msg: "invest",
                },
                gas: BOATLOAD_OF_GAS,
                deposit: utils.format.parseNearAmount("10")!,
              },
            },
          ],
        },
      ],
    });
  };

  const handleSubmit = useCallback(
    async (e: SubmitEvent) => {
      e.preventDefault();

      // TODO: Fix the typing so that target.elements exists..
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore.
      const { fieldset, message, donation } = e.target.elements;

      fieldset.disabled = true;

      // TODO: optimistically update page with new message,
      // update blockchain data in background
      // add uuid to each message, so we know which one is already known

      const wallet = await selector.wallet();

      wallet
        .signAndSendTransaction({
          signerId: accountId!,
          actions: [
            {
              type: "FunctionCall",
              params: {
                methodName: "addMessage",
                args: { text: message.value },
                gas: BOATLOAD_OF_GAS,
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                deposit: utils.format.parseNearAmount(donation.value || "0")!,
              },
            },
          ],
        })
        .catch((err) => {
          alert("Failed to add message");
          console.log("Failed to add message");

          throw err;
        })
        .then(() => {
          return getMessages()
            .then((nextMessages) => {
              setMessages(nextMessages);
              message.value = "";
              donation.value = SUGGESTED_DONATION;
              fieldset.disabled = false;
              message.focus();
            })
            .catch((err) => {
              alert("Failed to refresh messages");
              console.log("Failed to refresh messages");

              throw err;
            });
        })
        .catch((err) => {
          console.error(err);

          fieldset.disabled = false;
        });
    },
    [selector, accountId, getMessages],
  );

  if (loading) {
    return null;
  }

  const batchImportElementOld = (
    <div>
      <span
        style={{ cursor: "pointer" }}
        onClick={() => {
          navigator.clipboard.writeText(
            "6yjPa9QiwQC6RPwM1ho5BHXbyLkTJvADpgcWf9hjaPoj",
          );
        }}
      >
        6yjPa9QiwQC6RPwM1ho5BHXbyLkTJvADpgcWf9hjaPoj
      </span>
      <a
        target={"_blank"}
        href={`${NEAR_BASE_CONNECT_CONFIG_LOCALNET.nodeUrl}/batch-import?hash=${IMPORT_HASH}&network=testnet`}
      >
        <button>(OLD) Test Batch Import (localhost)</button>
      </a>
      <a
        target={"_blank"}
        href={`https://dev.wallet.meteorwallet.app/batch-import?hash=${IMPORT_HASH}&network=testnet`}
      >
        <button>(OLD) Test Batch Import (dev)</button>
      </a>
      <a
        target={"_blank"}
        href={`https://wallet.meteorwallet.app/batch-import?hash=${IMPORT_HASH}&network=testnet`}
      >
        <button>(OLD) Test Batch Import (production)</button>
      </a>
    </div>
  );

  const batchImportElementNew = (
    <div>
      <span
        style={{ cursor: "pointer" }}
        onClick={() => {
          navigator.clipboard.writeText(
            "6yjPa9QiwQC6RPwM1ho5BHXbyLkTJvADpgcWf9hjaPoj",
          );
        }}
      >
        6yjPa9QiwQC6RPwM1ho5BHXbyLkTJvADpgcWf9hjaPoj
      </span>
      <a
        target={"_blank"}
        href={`${NEAR_BASE_CONNECT_CONFIG_LOCALNET.nodeUrl}/batch-import?network=testnet#${IMPORT_HASH}`}
      >
        <button>Test Batch Import (localhost)</button>
      </a>
      <a
        target={"_blank"}
        href={`https://dev.wallet.meteorwallet.app/batch-import?network=testnet#${IMPORT_HASH}`}
      >
        <button>Test Batch Import (dev)</button>
      </a>
      <a
        target={"_blank"}
        href={`https://wallet.meteorwallet.app/batch-import?network=testnet#${IMPORT_HASH}`}
      >
        <button>Test Batch Import (production)</button>
      </a>
    </div>
  );

  const exportAccountTest = (
    <div>
      <button
        onClick={() => {
          // selector
          const exportModal = setupExportSelectorModal(selector, {
            accounts: [
              {
                accountId: "pebble.testnet",
                privateKey:
                  "ed25519:5pY2xhdx1YZNQtaYDiXSLFPXqmgEt8fxEVoYKwexX3icHWBUiEm2xxcZV93StBzKYP6NaS6uN6CbZt89gLkCAAqk",
              },
              {
                accountId: "pebbledev.testnet",
                privateKey:
                  "ed25519:4XQCi7E7jzdekDUuHry2uJYYqpfhMfPeC2nhUP12mS884iZvQGs2HGKKrfa5XrVZBGN1JCqS1kxZMRK4A6UAgBfi",
              },
            ],
          });

          exportModal.show();
        }}
      >
        Test Batch Export Account (pebbledev.testnet, pebble.testnet)
      </button>
    </div>
  );

  if (!account) {
    return (
      <Fragment>
        <div>
          <button onClick={handleSignIn}>Log in</button>
          <button onClick={handleVerifyOwner}>Verify Owner</button>
          <button onClick={handleSignMessage}>Sign Message</button>
        </div>
        {batchImportElementNew}
        {batchImportElementOld}
        <SignIn />
      </Fragment>
    );
  }

  return (
    <Fragment>
      <div>
        <div>
          <button onClick={handleSignOut}>Log out</button>
          <button onClick={handleSwitchProvider}>Switch Provider</button>
          {accounts.length > 1 && (
            <button onClick={handleSwitchAccount}>Switch Account</button>
          )}
          <button onClick={handleVerifyOwner}>Verify Owner</button>
          <button onClick={handleSignMessage}>Sign Message</button>
          {batchImportElementNew}
          {batchImportElementOld}
          <br />
          {exportAccountTest}
        </div>
        <br />
        <div>
          <button onClick={handleSendMultipleTransactions}>
            Send Multiple Transactions
          </button>
          <button onClick={handleSendMultipleTransactionsWithoutSignerId}>
            Send Multiple Transactions (No Signer)
          </button>
        </div>
        <br />
        <div>
          <button onClick={handleTransferSomeFundsToGuestbook}>
            Transfer some funds
          </button>
        </div>
        <br />
        <div>
          <button onClick={handleAddFullAccessKey}>
            Add a full access key (bad)
          </button>
          <button onClick={handleRemoveFullAccessKey}>
            Remove a full access key (bad)
          </button>
          <button onClick={handleContractDeploy}>
            Deploy contract to account (bad)
          </button>
          <button onClick={handleDeleteAccount}>Delete account (bad)</button>
        </div>
        <br />
        <div>
          <button onClick={handleSendWithYoctoNearTransactions}>
            Send With yocto Near Transactions
          </button>
          <button onClick={handleSendMultiNearTransactions}>
            Send Multiple Near Transactions
          </button>
          <button onClick={handleSendFtTransactions}>
            Send FT Transactions
          </button>
          <button onClick={handleSendFtWithNearTransactions}>
            Send FT With Near Transactions
          </button>
          <button onClick={handleSendMultiFtTransactions}>
            Send Multiple FT Transactions
          </button>
          <button onClick={handleSendManyManyFtTransactions}>
            Send Many Many FT Transactions
          </button>
        </div>
        <br />
        <div>
          <button onClick={handleSendNftTransactions}>
            Send Single NFT Transactions
          </button>
          <button onClick={handleSendNftWithNearTransactions}>
            Send NFT With Near Transactions
          </button>
          <button onClick={handleSendFtAndNftTransactions}>
            Send FT & NFT Transactions
          </button>
          <button onClick={handleSendManyFtAndNftTransactions}>
            Send Many NFT & NFT Transactions
          </button>
        </div>
      </div>
      <Form
        account={account}
        onSubmit={(e) => handleSubmit(e as unknown as SubmitEvent)}
      />
      <Messages messages={messages} />
    </Fragment>
  );
};

export default Content;
