export enum ENearNetwork {
  localnet = "localnet",
  testnet = "testnet",
  betanet = "betanet",
  mainnet = "mainnet",
}

export enum ENearNetworkSuffix {
  testnet = ".testnet",
  mainnet = ".near",
}
